<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-5">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12 text-center wow fadeInDown">
                            <h5>Hello, Welcome {{ namauser }}, Selamat Datang di <br><img
                                    src="../../../assets/img/berandaNgumahNew.png" alt="Admin Logo"
                                    class="animation__shake wow fadeInDown" style="opacity: .8;height: 80px;"></h5>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="small-box bg-info">
                                <div class="inner">
                                    <p class="text-center">Tahun Upload Data</p>
                                    <v-select :options="tahun" :reduce="(label) => label.code" label="label"
                                        v-model="defaultSelectedtahun" @update:modelValue="ontahunChange"
                                        style="background-color: #ffffff;color: black;"></v-select>
                                    <p class="text-center">&nbsp;</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-bag"></i>
                                </div>
                                <a href="javascript:void(0)" class="small-box-footer"> &nbsp;</a>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="small-box bg-warning">
                                <div class="inner">
                                    <h3>{{ G_numFormat(jumfolder) }}</h3>
                                    <p>Data<br></p><br>
                                </div>
                                <div class="icon">
                                    <i class="far fa-folder-open"></i>
                                </div>
                                <a href="javascript:void(0)" class="small-box-footer"> Jumlah Folder</a>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="small-box bg-info">
                                <div class="inner">
                                    <h3>{{ G_numFormat(jumfile) }}</h3>
                                    <p>Data<br></p><br>
                                </div>
                                <div class="icon">
                                    <i class="far fa-file-pdf"></i>
                                </div>
                                <a href="javascript:void(0)" class="small-box-footer"> Jumlah File</a>
                            </div>
                        </div>
                        <div class="col-sm-12" v-if="leveluser == 1 || leveluser == 8">
                            <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import Footer from "../componen/Footer.vue";
import NavBar from "../componen/NavBar.vue";
import Menu from "../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, apexchart: VueApexCharts,
    },
    data() {
        return {
            halamanloading: true,
            showFilter: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            kabkotuser: JSON.parse(localStorage.getItem("bdlsistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            datatahun: [],
            tahun: [],
            defaultSelectedtahun: {},
            selectedtahun: new Date().getFullYear(),
            sumdata: [],
            jumfolder: 0,
            jumfile: 0,
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                },
                yaxis: {
                    title: {
                        text: 'jumlah data'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val + " data"
                        }
                    }
                }
            },

        }
    },
    methods: {
        toggleShow() {
            this.showFilter = !this.showFilter;
        },
        ontahunChange(a) {
            try {
                this.selectedtahun = this.tahun[a].tag;
                var leveluser = this.leveluser;
                if ((leveluser == 1) || (leveluser == 8)) {
                    leveluser = '';
                }
                this.ambildata(leveluser, this.selectedtahun);
            }
            catch (err) {
                return;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambildatareff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var leveluser = this.leveluser;
            if ((leveluser == 1) || (leveluser == 8)) {
                leveluser = '';
            }
            var random = Math.random();
            await mainAPI.get("parastapainnovation_dashboard-GetTahunData?random=" + random + "&level=" + leveluser).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.datatahun = [];
                        this.tahun = [];
                        this.datatahun = Response.data.content.data;
                        this.datatahun.forEach((item) => {
                            if (this.datatahun.indexOf(item) == 0) {
                                this.tahun.push({
                                    label: item.tahun,
                                    code: 0,
                                    tag: item.tahun,
                                });

                                this.defaultSelectedtahun = {
                                    label: item.tahun,
                                    code: 0,
                                    tag: item.tahun,
                                };
                                this.selectedtahun = item.tahun;
                            }
                            else {
                                this.tahun.push({
                                    label: item.tahun,
                                    code: this.datatahun.indexOf(item),
                                    tag: item.tahun,
                                });
                            }
                        });

                    }
                    else {
                        this.datatahun = [];
                        this.tahun = [];
                        this.tahun.push({
                            label: new Date().getFullYear(),
                            code: 0,
                            tag: new Date().getFullYear(),
                        });
                        this.defaultSelectedtahun = {
                            label: new Date().getFullYear(),
                            code: 0,
                            tag: new Date().getFullYear(),
                        };
                        this.selectedtahun = new Date().getFullYear();
                    }
                    this.ambildata(leveluser, this.selectedtahun);
                    //this.halamanloading = false;
                }
            ).catch(error => {
                this.datatahun = [];
                this.tahun = [];
                this.halamanloading = false;
            });
        },
        async ambildata(level, tahun) {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_dashboard-GetSumData?random=" + random + "&level=" + level + "&tahun=" + tahun).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.sumdata = [];
                        this.sumdata = Response.data.content.data;
                        this.sumdata.forEach((item) => {
                            if (item.tipe == 'folder') {
                                this.jumfolder = item.jumlah;
                            }
                            else {
                                this.jumfile = item.jumlah;
                            }
                        });
                        this.series = [{
                            name: 'Folder',
                            data: Response.data.content.folder
                        }, {
                            name: 'File',
                            data: Response.data.content.file
                        }];
                        this.chartOptions = {
                            xaxis: {
                                categories: Response.data.content.bagian,
                            },
                        };
                    }
                    else {
                        this.sumdata = [];
                        this.series = [];
                        this.chartOptions = {
                            xaxis: {
                                categories: [],
                            },
                        };
                    }

                    this.halamanloading = false;
                }
            ).catch(error => {
                this.sumdata = [];
                this.halamanloading = false;
            });
        }
    },
    mounted() {
        this.getOtentifikasi();
        //alert(this.leveluser);
        //this.halamanloading = false;
        this.ambildatareff();
    }
}
</script>
<style></style>