<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <center>
            <div class="modal fade" id="costumModalpencarian" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Manajemen Pencarian Folder dan File</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="row">
                                <div class="col-sm-10">
                                    <input type="text" id="pencarian" name="pencarian"
                                        class="form-control form-control-sm"
                                        placeholder="Masukkan Kata Kunci Pencarian Folder dan File">
                                </div>
                                <div class="col-sm-2">
                                    <button type="button" class="btn btn-success btn-block text-white2 btn-sm"
                                        @click="carisemua()" id="tombol"><i class="fa fa-search"></i> Cari</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">&nbsp;</div>
                            </div>
                            <div class="row">
                                <template v-for="(datalist, urutlist) in datacari" :key="urutlist">
                                    <div class="col-md-4 col-sm-6 col-12" v-if="datalist.tipe == 'folder'">
                                        <div class="info-box" style="cursor: pointer;">
                                            <span class="info-box-icon bg-info"><i class="far fa-folder"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text" @click="pindahfolder(datalist.id)">{{
                                                    datalist.nama
                                                    }}</span>
                                            </div>
                                        </div>
                                        <!-- /.info-box -->
                                    </div>
                                    <div class="col-md-12 col-sm-6 col-12" v-if="datalist.tipe == 'file'">
                                        <a :href="folder_foto + datalist.file" class="hitam"><i
                                                class="far fa-file-pdf"></i> {{
                                                    datalist.nama }}</a>
                                    </div>


                                </template>
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-danger2 btn-block text-white lebar2"
                                data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
        </center>
        <center>
            <div class="modal fade" id="costumModaldisclaimer" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Manajemen Folder</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <input type="text" v-model="namafolder" id="namafolder" name="namafolder"
                                    class="form-control form-control-sm" placeholder="Isikan nama folder">
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-success btn-block text-white lebar2"
                                @click="simpanfolder()" id="tombol">Simpan</button>
                            <button type="button" class="btn btn-danger2 btn-block text-white lebar2"
                                data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
        </center>
        <center>
            <div class="modal fade" id="costumModaldisclaimer2" data-easein="flipBounceYIn" tabindex="-1" role="dialog"
                aria-labelledby="costumModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
                style="margin: auto;">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Manajemen File</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body text-justify">
                            <p>
                            <div class="col-sm-12">
                                <!--IKON PETA 1-->
                                <div class="col-sm-12">
                                    <label>File<small class="text-info"> (*pdf)</small>
                                    </label>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="far fa-file-pdf"></i></span>
                                        </div>
                                        <input type="file" class="form-control form-control-sm"
                                            id="fileToUploadfilenya_data" name="fileToUploadfilenya_data"
                                            placeholder="fileToUpload" style="font-size: smaller;"
                                            accept="application/pdf">
                                        <input type="hidden" class="form-control form-control-sm" id="filenya_data"
                                            name="filenya_data" placeholder="File Beranda">
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <label>Opsi</label>
                                    <div class="row">
                                        <div class="col-sm-9">
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                    id="persennyafilenya_data">
                                                    <span id="tulisanpersenfilenya_data">0%</span>
                                                </div>
                                            </div>
                                            <br>
                                            <span class="text-success" id="suksesnyafilenya_data"></span>
                                        </div>
                                        <div class="col-sm-3 text-center">
                                            <button type="button" class="btn btn-warning btn-sm lebar text-white"
                                                @click="uploadfile('filenya_data', 'application/pdf')">Upload
                                                File</button>
                                            <button type="button" class="btn btn-danger btn-sm lebar"
                                                @click="clearfilepeta('filenya_data')">Hapus File</button>
                                        </div>
                                    </div>
                                </div>
                                <!--IKON PETA 1 END-->
                            </div>
                            </p>
                        </div>
                        <div class="modal-footer justify-content-between">
                            <button type="button" class="btn btn-success btn-block text-white lebar2"
                                @click="simpanfile('filenya_data')">Simpan</button>
                            <button type="button" class="btn btn-danger2 btn-block text-white lebar2"
                                data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                    <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
            </div>
        </center>
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-1 wow fadeInDown">
                            <h5>Data</h5>
                        </div>
                        <div class="col-sm-11 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active"><a :href="'/Data7/'+folderawal">TU </a></li>
                                <li v-for="(datalist, urutlist) in menu" :key="urutlist" class="breadcrumb-item active">
                                    <a href="javascript:void(0)" @click="pindahfolder(datalist.id)">
                                        {{ datalist.nama }}
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="col-sm-2 float-left pb-2">
                                        <button type="button" class="btn btn-danger3 btn-sm text-white lebar2"
                                            @click="caridata()" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fa fa-search"></i> Pencarian
                                        </button>
                                    </div>
                                    <div class="col-sm-2 float-left pb-2" v-if="leveluser != 8">
                                        <button type="button" class="btn btn-warning btn-sm text-white lebar2"
                                            @click="tambahfolder()" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah Folder
                                        </button>
                                    </div>
                                    <div class="col-sm-2 float-left pb-2" v-if="leveluser != 8">
                                        <button type="button" class="btn btn-success3 btn-sm text-white lebar2"
                                            @click="tambahfile()" data-toggle="tooltip" data-placement="left"
                                            title="Tambah data..">
                                            <i class="fas fa-plus"></i> Tambah File
                                        </button>
                                    </div>
                                    <div class="col-sm-6 float-left pb-2" v-if="leveluser != 8">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                    <div class="col-sm-10 float-left pb-2" v-if="leveluser == 8">
                                        <input type="text" v-model="carikata" class="form-control form-control-sm"
                                            placeholder="Masukkan Kata Kunci Pencarian">
                                    </div>
                                </div>
                                <div class="card-body p-0">

                                </div>
                            </div>
                        </div>
                        <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                            <div class="col-md-4 col-sm-6 col-12" v-if="datalist.tipe == 'folder'">
                                <div class="info-box" style="cursor: pointer;">
                                    <span class="info-box-icon bg-info"><i class="far fa-folder"></i></span>
                                    <div class="info-box-content">
                                        <span class="info-box-text" @click="pindahfolder(datalist.id)">{{ datalist.nama
                                            }}</span>
                                    </div>
                                    <!-- /.info-box-content -->
                                    <div class="btn-group show" v-if="leveluser != 8">
                                        <button type="button" class="btn btn-tool dropdown-toggle"
                                            data-toggle="dropdown" aria-expanded="true">
                                            <i class="fas fa-wrench"></i>
                                        </button>
                                        <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="top-end"
                                            style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, -160px, 0px);">
                                            <a href="javascript:void(0)" class="dropdown-item"
                                                @click="edit(datalist.id)">Edit</a>
                                            <a href="javascript:void(0)" class="dropdown-item"
                                                @click="hapus(datalist.id)">Hapus</a>
                                        </div>
                                    </div>
                                </div>
                                <!-- /.info-box -->
                            </div>
                            <div class="col-md-12 col-sm-6 col-12" v-if="datalist.tipe == 'file'">
                                <a :href="folder_foto + datalist.file" class="hitam"><i class="far fa-file-pdf"></i> {{
                                    datalist.nama }}</a>
                                <div class="btn-group show" v-if="leveluser != 8">
                                    <button type="button" class="btn btn-tool dropdown-toggle" data-toggle="dropdown"
                                        aria-expanded="true">
                                        <i class="fas fa-wrench"></i>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right" role="menu" x-placement="top-end"
                                        style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-124px, -160px, 0px);">
                                        <a :href="folder_foto + datalist.file" class="dropdown-item">Unduh</a>
                                        <a href="javascript:void(0)" class="dropdown-item"
                                            @click="hapusfile(datalist.id)">Hapus</a>
                                    </div>
                                </div>
                            </div>


                        </template>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            datacari: [],
            carikata: '',
            namafolder: '',
            idfolder: '',
            level: 7,
            menu: [],
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            folder: encodeURIComponent(CryptoJS.AES.encrypt(decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)), 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_data-PDF?f=',
            folderawal: encodeURIComponent(CryptoJS.AES.encrypt('0', 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString()),
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
    },
    methods: {
        caridata() {
            $('#costumModalpencarian').modal('show');
        },
        async carisemua() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var katakunci = document.getElementById('pencarian').value;
            var random = Math.random();
            await mainAPI.get("parastapainnovation_folder-Search?random=" + random + "&katakunci=" + katakunci + "&level=" + this.level).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.datacari = [];
                        this.datacari = Response.data.content.data;
                    }
                    else {
                        this.datacari = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datacari = [];
                this.halamanloading = false;
            });
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        pindahfolder(kode) {
            var bytes = encodeURIComponent(CryptoJS.AES.encrypt(kode, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString());
            window.location.replace("/Data7/" + bytes);
        },
        tambahfolder() {
            document.getElementById('tombol').innerHTML = 'Simpan';
            this.namafolder = '';
            this.idfolder = '';
            $('#costumModaldisclaimer').modal('show');
        },
        tambahfile() {
            $('#costumModaldisclaimer2').modal('show');
        },
        simpanfile(idnya) {
            if (document.getElementById('filenya_data').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan File',
                });
                return false;
            }

            var elmnt = document.getElementById("fileToUpload" + idnya);
            var namanya = elmnt.files[0].name;
            var fd = new FormData();
            fd.append("parent", this.secretencData);
            fd.append("level", this.level);
            fd.append("nama", namanya);
            fd.append("id", this.idfolder);
            fd.append("filenya_data", document.getElementById('filenya_data').value);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_file-AddFile", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        //alert(this.folder);
                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                            $('#costumModaldisclaimer2').modal('hide');
                        });
                        this.ambildata();
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
        },
        simpanfolder() {
            if (document.getElementById('namafolder').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('namafolder').focus();
                return false;
            }
            var fd = new FormData();
            fd.append("parent", this.secretencData);
            fd.append("level", this.level);
            fd.append("nama", this.namafolder);
            fd.append("id", this.idfolder);
            fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            if (document.getElementById('tombol').innerHTML == 'Simpan') {
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("parastapainnovation_folder-AddFolder", fd).then(
                    Response => {
                        if (Response.data.response == 'error') {
                            swal.fire('Peringatan', Response.data.message, 'error');
                        }
                        else {
                            //alert(this.folder);
                            swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                $('#costumModaldisclaimer').modal('hide');
                            });
                            this.ambildata();
                        }
                    }
                ).catch(function (error) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error
                    });
                }
                )
            }
            if (document.getElementById('tombol').innerHTML == 'Update') {
                const mainAPI = axios.create({
                    baseURL: process.env.VUE_APP_URL_API,
                    headers: {
                        "Content-Type": "form-data",
                        Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                    },
                });
                mainAPI.post("parastapainnovation_folder-UpdateFolder", fd).then(
                    Response => {
                        if (Response.data.response == 'error') {
                            swal.fire('Peringatan', Response.data.message, 'error');
                        }
                        else {
                            //alert(this.folder);
                            swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                $('#costumModaldisclaimer').modal('hide');
                            });
                            this.ambildata();
                        }
                    }
                ).catch(function (error) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: error
                    });
                }
                )
            }


        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_beranda = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_folder-GetFolder?random=" + random + "&parent=" + this.secretencData + "&level=" + this.level).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                    }
                    else {
                        this.datamaster = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamaster = [];
                this.halamanloading = false;
            });
            await mainAPI.get("parastapainnovation_rekursiv-parent?random=" + random + "&id=" + this.secretencData + "&level=" + this.level).then(
                Response => {
                    this.datapesan = Response.data.message;
                    this.menu = [];
                    if (this.datapesan == 'data diketemukan') {
                        this.menu = Response.data.content.data;
                    }
                    else {
                        this.menu = [];
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.menu = [];
                this.halamanloading = false;
            });
        },
        edit(kode) {
            document.getElementById('tombol').innerHTML = 'Update';
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            mainAPI.get("parastapainnovation_folder-GetFolderByID?random=" + random + "&id=" + kode).then(
                Response => {
                    this.datapesan = Response.data.message;

                    if (this.datapesan == 'data diketemukan') {
                        this.namafolder = Response.data.content.data[0].nama;
                        this.idfolder = Response.data.content.data[0].id;
                    }
                    else {
                        this.namafolder = '';
                        this.idfolder = '';
                    }
                    $('#costumModaldisclaimer').modal('show');
                    this.halamanloading = false;
                }
            ).catch(error => {
                this.datamaster = [];
                $('#costumModaldisclaimer').modal('show');
                this.halamanloading = false;
            });

        },
        hapusfile(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#2176e6",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_file-DeleteFile", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {

                                });
                                this.ambildata();
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#2176e6",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_folder-DeleteFolder", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {

                                });
                                this.ambildata();
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambildata();
    }
}
</script>
<style></style>