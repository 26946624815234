import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Beranda',
    component: () => import('../../components/views/Publik/Beranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../../components/views/Publik/Login.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/KontakKami',
    name: 'KontakKami',
    component: () => import('../../components/views/Publik/KontakKami.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: () => import('../../components/views/Dashboard/Dashboard.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Slider',
    name: 'Slider',
    component: () => import('../../components/views/Backoffice/Slider/Slider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesSlider/:id',
    name: 'ProsesSlider',
    component: () => import('../../components/views/Backoffice/Slider/ProsesSlider.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/InfoBeranda',
    name: 'InfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/InfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesInfoBeranda/:id',
    name: 'ProsesInfoBeranda',
    component: () => import('../../components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/User',
    name: 'User',
    component: () => import('../../components/views/Backoffice/User/User.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/ProsesUser/:id',
    name: 'ProsesUser',
    component: () => import('../../components/views/Backoffice/User/ProsesUser.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data/:id',
    name: 'Data',
    component: () => import('../../components/views/Backoffice/Data/Data.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data3/:id',
    name: 'Data3',
    component: () => import('../../components/views/Backoffice/Data/Data3.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data4/:id',
    name: 'Data4',
    component: () => import('../../components/views/Backoffice/Data/Data4.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data5/:id',
    name: 'Data5',
    component: () => import('../../components/views/Backoffice/Data/Data5.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data6/:id',
    name: 'Data6',
    component: () => import('../../components/views/Backoffice/Data/Data6.vue'),
    meta: {
      transition: "zoom",
    },
  },
  {
    path: '/Data7/:id',
    name: 'Data7',
    component: () => import('../../components/views/Backoffice/Data/Data7.vue'),
    meta: {
      transition: "zoom",
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: 'history',
  routes: routes,
  linkActiveClass: 'active',
})

export default router;
